import React from "react";
import ButtonWithIcon from "../../../../Component/ButtonWithIcon";
import AccountUser from "../../../Entity/AccountUser/AccountUser";
import {NavigateFunction, useNavigate} from "react-router-dom";

interface AccountUserCardProps {
    readonly accountUser: AccountUser;
}

const AccountUserCard = (props: AccountUserCardProps): React.JSX.Element => {
    const navigate: NavigateFunction = useNavigate();

    function editAccountUser(): void
    {
        navigate('/anwender/editor/' + props.accountUser.id);
    }

    return (
        <div className="board card-shadow card mx-sm-3 mx-1 p-1">
            <div className="card-header">
                {props.accountUser.firstname} {props.accountUser.name}
            </div>
            <div className="card-body h1">
                {props.accountUser.firstname?.slice(0, 1).toUpperCase()}
                {props.accountUser.name?.slice(0, 1).toUpperCase()}
            </div>
            <div className="card-footer">
                <ButtonWithIcon icon="bi-pencil" additionalClassName={"fa-pull-right"} text="Bearbeiten"
                                onClick={editAccountUser} iconType="Bootstrap"/>
            </div>
        </div>
    );
}

export default AccountUserCard;