import AuthenticationRequest from '../Entity/Authentication/AuthenticationRequest';
import AuthenticationResponse from '../Entity/Authentication/AuthenticationResponse';
import Authentication from '../Entity/Authentication/Authentication';
import ApiV1Provider from '../../Api/ApiV1Provider';
import LocalStorageService from '../LocalStorage/LocalStorageService';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

class AuthenticationService {

    private readonly apiV1Provider: ApiV1Provider
    private readonly localStorageService: LocalStorageService;

    constructor() {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api');
        this.localStorageService = new LocalStorageService();
    }

    public async authenticate(authenticationRequest: AuthenticationRequest): Promise<Authentication|null> {

        const config: AxiosRequestConfig<AuthenticationRequest> = {headers: {'Content-Type': 'application/json'}};

        try {
            const response: AxiosResponse<AuthenticationResponse> = await this.apiV1Provider.post('/login', authenticationRequest, config);
            return response.data;
        } catch (error) {
            return null;
        }
    }

    public async authenticateUser(authenticationRequest: AuthenticationRequest): Promise<Authentication|null> {
        const authentication: Authentication|null = await this.authenticate(authenticationRequest);

        if (authentication === null) {
            return null;
        }

        this.localStorageService.saveToStorage<Authentication>('authentication', authentication, 7);

        return this.fetchAuthentication();
    }

    public deAuthenticateUser(): void {
        this.localStorageService.removeFromStorage('accountUser');
        this.localStorageService.removeFromStorage('authentication');
    }

    public fetchAuthentication(): Authentication|null {
        const authentication: Authentication|null = this.localStorageService.getFromStorage<Authentication>('authentication');

        if (authentication === null) {
            return null;
        }

        return authentication;
    }
}

export default AuthenticationService;