import React, {useCallback, useEffect, useState} from "react";
import Component from "../../../Entity/Recipe/Component";
import component from "../../../Entity/Recipe/Component";
import {Card, Tab, Tabs} from "react-bootstrap";
import AutocompleteItem from "../../../../Component/Form/Field/AutocompleteItem";
import IngredientService from "../../../Ingredient/IngredientService";
import Ingredient from "../../../Entity/Recipe/Ingredient";
import Authentication from "../../../Entity/Authentication/Authentication";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import Unit from "../../../Entity/Enums/Unit";
import Recipe from "../../../Entity/Recipe/Recipe";
import IngredientForm from "../../Component/Recipt/IngredientForm";
import InputField from "../../../../Component/Form/Field/InputField";
import FormValidationHandler from "../../../FormValidationHandler/FormValidationHandler";
import FieldValidationDefinition from "../../../FormValidationHandler/FieldValidationDefinition";
import FormData from "../../../Entity/Form/FormData";
import ButtonWithIcon from "../../../../Component/ButtonWithIcon";
import TextAreaField from "../../../../Component/Form/Field/TextAreaField";
import WorkingStep from "../../../Entity/Recipe/WorkingStep";
import WorkingStepForm from "../../Component/Recipt/WorkingStepForm";
import WorkingStepService from "../../../WorkingStep/WorkingStepService";
import IconButton from "../../../../Component/IconButton";
import FloatingIcon from "../../../../Component/FloatingIcon";

interface RecipeComponentProps {
    component: Component;
    recipe: Recipe;
    index: number;
    autocompleteItems: AutocompleteItem[];
    addComponentCallback: Function;
    removeComponentCallback?: Function;
}

const RecipeComponent = (props: RecipeComponentProps): React.JSX.Element => {
    const component: Component = props.component;
    const fieldValidationDefinitions: FieldValidationDefinition<component>[] = [];
    const formValidationHandler: FormValidationHandler<Component> = new FormValidationHandler<Component>(fieldValidationDefinitions);
    const [formData, setFormData] = useState<FormData<Component>>({data: props.component});
    const [ingredients, setIngredients] = useState<Ingredient[]>(props.component.ingredients);
    const [workingSteps, setWorkingSteps] = useState<WorkingStep[]>(props.component.workingSteps);
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    let lastKeyEventFromStepTextField: string[] = ['',''];
    let lastKeyEventIndexFromStepTextField: number  = 0;
    const [textAreaRef,setTextAreaRef] = useState<React.RefObject<HTMLTextAreaElement>|null>(null);


    useEffect((): void => {

        component.ingredients = ingredients;
        return;

    }, [ingredients]);

    useEffect((): void => {
        props.component.workingSteps = workingSteps;

    }, [workingSteps]);


    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        const name: string = event.target.name;
        (component as any)[name] = event.target.value;

        updateFormData();
        validateField(name);
    };


    const updateFormData = (): void => {
        setFormData({...formData, data: component});
    };

    const validateField = (fieldName: string): void => {
        if (formValidationHandler === undefined) {
            return;
        }

        formValidationHandler.validateField(fieldName, formData);
        setFormData({...formData, errors: formData.errors});
    };

    const removeIngredient = (index: number): void =>
    {
        if (ingredients === undefined || authentication === null) {
            return;
        }
        const ingredientService: IngredientService = new IngredientService(authentication);
        const newInredients: Ingredient[] = [];

        ingredients.map((ingredient: Ingredient, position: number): void => {
            if (position === index) {
                if (ingredient.id === undefined) {
                    return;
                }
                ingredientService.delete(props.recipe.id, props.component.id, ingredient.id);
                return;
            }
            newInredients.push(ingredient);
        });

        setIngredients(newInredients);
    }

    const removeWorkingStep = (index: number): void => {
        if (workingSteps === undefined || authentication === null) {
            return;
        }
        const workingStepService: WorkingStepService = new WorkingStepService(authentication);
        const newWorkingSteps: WorkingStep[] = [];

        workingSteps.map((workingStep: WorkingStep, position: number): void => {
            if (position === index) {
                if (workingStep.id === undefined) {
                    return;
                }
                workingStepService.delete(props.recipe.id, props.component.id, workingStep.id);
                return;
            }
            newWorkingSteps.push(workingStep);
        });

        setWorkingSteps(newWorkingSteps);
    }

    const scrollToLastElement: (node: HTMLDivElement) => void = useCallback(node => {
        if (node !== null) {
            node.scrollIntoView();
        }
    }, []);

    function clickAdd(): void
    {
        props.addComponentCallback();
    }

    function clickRemove(): void
    {
        if (props.removeComponentCallback === undefined) {
            return;
        }
        props.removeComponentCallback(props.index);
    }

    function stepOnKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>): void
    {
        if (
            lastKeyEventFromStepTextField[0] === event.key
            && lastKeyEventFromStepTextField[1] === event.key
            && event.key === 'Enter'
        ) {
            appendWorkingStep();
            event.preventDefault();

            return;
        }

        lastKeyEventFromStepTextField[lastKeyEventIndexFromStepTextField] = event.key;
        lastKeyEventIndexFromStepTextField++;

        if (lastKeyEventIndexFromStepTextField > 1) {
            lastKeyEventIndexFromStepTextField = 0;
        }
    }

    function appendWorkingStep(): void
    {
        const newWorkingSteps: WorkingStep[] = [];
        const workingStep: WorkingStep = new WorkingStep();

        workingSteps.map((workingStep: WorkingStep): void => {
            newWorkingSteps.push(workingStep);
        });

        lastKeyEventFromStepTextField = ['',''];
        lastKeyEventIndexFromStepTextField = 0;

        newWorkingSteps.push(workingStep);
        setWorkingSteps(newWorkingSteps);
    }

    function handleFocusName(event: React.FocusEvent<HTMLInputElement>): void
    {
        if (event.target.value === 'Zubereitung') {
            event.target.value = '';
        }
    }

    function handleOnBlurName(event: React.FocusEvent<HTMLInputElement>): void
    {
        if (event.target.value === '') {
            event.target.value = 'Zubereitung';
        }
    }

    function addIngredient(): void
    {
        const elements: Ingredient[] = [];

        ingredients.map(
            function(e: Ingredient): void {
                elements.push(e);
            }
        );
        const e: Ingredient = new Ingredient();
        e.unit = Unit.GRAM;

        elements.push(e);

        setIngredients(elements);
    }

    return (
            <Card>
                <Card.Body>
                    <div className="row mb-4">
                        <div className="col-md-9 col-8">
                            <InputField
                                name="name"
                                label="Abschnitt"
                                type="text"
                                value={component.name}
                                onChange={handleChange}
                                onFocus={handleFocusName}
                                onBlur={handleOnBlurName}
                                required={true}
                                formErrors={FormValidationHandler.getFieldErrors(formData, 'persons')}
                            />
                        </div>
                        <div className="col-md-3 col-4">
                            <div className="row">
                                <div className={(props.removeComponentCallback === undefined) ? 'col-12' : 'col-6'}>
                                    <ButtonWithIcon icon="bi-file-plus" iconType="Bootstrap" text="Abschnitt hinzufügen"
                                                    onClick={clickAdd}/>
                                </div>
                                {props.removeComponentCallback !== undefined &&
                                    <div className="col-6">
                                        <ButtonWithIcon icon="bi-trash3" iconType="Bootstrap" text="Abschnitt entfernen"
                                                        additionalClassName="delete-button" onClick={clickRemove}/>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Tabs defaultActiveKey="tab1" id="card-tabs">
                        <Tab eventKey="tab1" title="Zutaten">
                            <div className="row">
                                <div className="col-12">
                                {ingredients !== undefined && ingredients.length > 0 &&
                                    <div className="mb-5 mb-lg-4 recipe-ingredient-list p-3">
                                        {ingredients.map((ingredient: Ingredient, index: number): React.JSX.Element => {
                                            return <>
                                                {ingredients.length === index + 1 &&
                                                    <div ref={scrollToLastElement}></div>
                                                }
                                                <IngredientForm
                                                    ingredient={ingredient}
                                                    component={component}
                                                    index={index}
                                                    onRemove={removeIngredient}
                                                    ingredientAutocompleteSuggestions={props.autocompleteItems}
                                                />
                                            </>;
                                        })}
                                    </div>
                                }
                                </div>
                            </div>
                            <FloatingIcon icon="fa-square-plus" iconType="FontAwsome" onClick={addIngredient} additionalClassName="floating-absolute"  align="right" />
                        </Tab>
                        <Tab eventKey="tab2" title="Zubereitung">
                            <div className="row">
                                <div className="col-12">
                                    {workingSteps !== undefined && workingSteps.length > 0 &&
                                        <div className="mb-5 mb-lg-4 recipe-ingredient-list p-3">
                                            {workingSteps.map((workingStep: WorkingStep, index: number): React.JSX.Element => {
                                                return <>
                                                    {workingSteps.length === index + 1 &&
                                                        <div ref={scrollToLastElement}></div>
                                                    }
                                                    <WorkingStepForm component={component} workingStep={workingStep} index={index}
                                                                     onRemove={removeWorkingStep}/>
                                                </>;
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                            <FloatingIcon icon="fa-square-plus" iconType="FontAwsome" onClick={appendWorkingStep} additionalClassName="floating-absolute"  align="right" />
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
    );
}

export default RecipeComponent;