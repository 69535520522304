import React, {useEffect, useState} from "react";
import Breadcrumb from "../Component/Breadcrumb";
import {Link, useParams} from "react-router-dom";
import Board from "../Component/Board";
import RecipeCategory from "../../Entity/RecipeCategory/RecipeCategory";
import RecipeCategoryService from "../../RecipeCategory/RecipeCategoryService";
import AuthenticationService from "../../Authentication/AuthenticationService";
import Authentication from "../../Entity/Authentication/Authentication";
import SpinnerOverlay from "../../../Component/SpinnerOverlay";
import Recipe from "../../Entity/Recipe/Recipe";
import RecipeService from "../../Recipe/RecipeService";
import DifficultyLevel from "../../Entity/Enums/DifficultyLevel";
import PictureSlider from "../Component/PictureSlider";
import Picture from "../../Entity/Picture/Picture";
import PictureCard from "../Component/PictureCard";
import PictureService from "../../Image/PictureService";
import Component from "../../Entity/Recipe/Component";
import ComponentService from "../../Recipe/ComponentService";
import Ingredient from "../../Entity/Recipe/Ingredient";
import Unit from "../../Entity/Enums/Unit";
import WorkingStep from "../../Entity/Recipe/WorkingStep";

interface RecipeViewProps {

}

const RecipeView = (props: RecipeViewProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {recipeId} = useParams<string>();
    const {categoryId} = useParams<string>();
    const [recipeCategory, setRecipeCategory] = useState<RecipeCategory|undefined>(undefined);
    const [recipe, setRecipe] = useState<Recipe|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [recipeTotalTime, setRecipeTotalTime] = useState<number>(0);
    const [pictures, setPictures] = useState<Picture[]|null|undefined>(undefined);
    const [imagesPerSlide, setImagesPerSlide] = useState<number|undefined>(undefined);
    const [components, setComponents] = useState<Component[]|undefined>(undefined);
    const windowWidth: number = window.innerWidth;

    useEffect((): void => {
        if (recipe !== undefined) {
            return;
        }

        loadRecipe();
    }, [recipeId]);

    useEffect((): void => {
        if (recipeCategory !== undefined) {
            return;
        }

        loadRecipeCategory();
    }, [categoryId]);

    useEffect((): void => {
        if (imagesPerSlide !== undefined) {
            return;
        }
        if (windowWidth <= 387) {
            setImagesPerSlide(1);
        } else if (windowWidth <= 1205) {
            setImagesPerSlide(3);
        } else {
            setImagesPerSlide(5);
        }

    }, [imagesPerSlide]);

    useEffect((): void => {
        if (recipe === undefined || pictures !== undefined) {
            return;
        }

        loadPictures();

    }, [recipe, pictures]);

    useEffect((): void => {
        if (components !== undefined) {
            return;
        }

        loadComponents();

    }, [recipe]);

    async function loadRecipeCategory(): Promise<void>
    {
        if (authentication === null || categoryId === undefined) {
            return;
        }
        const id: number = parseInt(categoryId);

        const recipeCategoryService: RecipeCategoryService = new RecipeCategoryService(authentication);
        setRecipeCategory(await recipeCategoryService.fetchById(id));
    }

    async function loadRecipe(): Promise<void>
    {
        if(authentication === null || recipeId === undefined) {
            return;
        }

        const recipeService: RecipeService = new RecipeService(authentication);
        const recipe: Recipe = await recipeService.fetchById(parseInt(recipeId));

        if (recipe !== null) {

            if (recipe.cookingTime !== null && recipe.preparationTime !== null) {
                setRecipeTotalTime(recipe.cookingTime + recipe.preparationTime);
            } else if (recipe.cookingTime !== null) {
                setRecipeTotalTime(recipe.cookingTime);
            } else if (recipe.preparationTime !== null) {
                setRecipeTotalTime(recipe.preparationTime);
            }
        }

        setRecipe(recipe);
    }

    async function loadPictures(): Promise<void>
    {
        if (authentication === null || recipe === undefined || imagesPerSlide === undefined) {
            return;
        }

        const pictureService: PictureService = new PictureService(authentication);
        const picturesByRecipe: Picture[] =  await pictureService.getByRecipeId(recipe.id);

        if (picturesByRecipe.length <= 0) {
            setPictures(null);
            return;
        }

        setPictures(picturesByRecipe);
    }

    async function loadComponents(): Promise<void>
    {
        if (authentication === null || recipe === undefined) {
            setIsLoading(false);
            return;
        }
        const componentService: ComponentService = new ComponentService(authentication);
        const components: Component[] = await componentService.fetchByRecipeId(recipe.id);

        setComponents(components);
        setIsLoading(false);
    }

    function getDifficultyLevelName(difficultyLevel: number): string
    {
        if (difficultyLevel === DifficultyLevel.LOW) {
            return 'Einfach';
        }

        if (difficultyLevel === DifficultyLevel.MEDIUM) {
            return 'Mittel';
        }

        if (difficultyLevel === DifficultyLevel.HIGH) {
            return 'Schwer';
        }

        return '';
    }

    function getRecipeImage(picture: Picture): React.JSX.Element
    {
        return (<PictureCard picture={picture} />);
    }

    if (recipe === undefined || recipe === null) {
        return (
            <SpinnerOverlay />
        );
    }

    function getUnitShortName(unit: Unit): string
    {
        if (unit === Unit.GRAM) {
            return 'Gr';
        }

        if (unit === Unit.KILOGRAM) {
            return 'Kg';
        }

        if (unit === Unit.MILLIGRAM) {
            return 'Mg';
        }

        if (unit === Unit.OUNCE) {
            return 'Oz';
        }

        if (unit === Unit.POUND) {
            return 'Pf';
        }

        if (unit === Unit.LITRE) {
            return 'L';
        }

        if (unit === Unit.MILLILITRE) {
            return 'Ml';
        }

        if (unit === Unit.CUP) {
            return 'T';
        }

        if (unit === Unit.TEASPOON) {
            return 'TL';
        }

        if (unit === Unit.TABLESPOON) {
            return "EL"
        }

        if (unit === Unit.PIECE) {
            return 'Stk';
        }

        if (unit === Unit.PINCH) {
            return 'Pr'
        }

        if (unit === Unit.TINS) {
            return 'D';
        }

        return '';
    }

    return (
        <>
            {isLoading === true &&
                <SpinnerOverlay />
            }
            <Breadcrumb>
                {recipeCategory !== undefined &&
                    <Link to={'/rezepte/' + categoryId}>{recipeCategory.name}</Link>
                }
                &nbsp;/ {recipe.name}
            </Breadcrumb>
            <Board>
                <div className="row">
                    <div className="col-12">
                        <div className="h1">{recipe.name}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                Vorbereitungszeit:&nbsp;
                                {recipe.preparationTime !== null &&
                                    <>
                                        {Math.floor(recipe.preparationTime / 3600) > 0 &&
                                            <>
                                                {Math.floor(recipe.preparationTime / 3600)} Std.&nbsp;
                                            </>
                                        }
                                        {Math.floor((recipe.preparationTime % 3600) / 60) > 0 &&
                                            <>
                                                {Math.floor((recipe.preparationTime % 3600) / 60)} Min.
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <div className="col">
                                Kochzeit:&nbsp;
                                {recipe.cookingTime !== null &&
                                    <>
                                        {Math.floor(recipe.cookingTime / 3600) > 0 &&
                                            <>
                                                {Math.floor(recipe.cookingTime / 3600)} Std.&nbsp;
                                            </>
                                        }
                                        {Math.floor((recipe.cookingTime % 3600) / 60) > 0 &&
                                            <>
                                                {Math.floor((recipe.cookingTime % 3600) / 60)} Min.
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <div className="col">
                                Gesamtzeit:&nbsp;
                                {recipeTotalTime > 0 &&
                                    <>
                                        {Math.floor(recipeTotalTime / 3600) > 0 &&
                                            <>
                                                {Math.floor(recipeTotalTime / 3600)} Std.&nbsp;
                                            </>
                                        }
                                        {Math.floor((recipeTotalTime % 3600) / 60) > 0 &&
                                            <>
                                                {Math.floor((recipeTotalTime % 3600) / 60)} Min.
                                            </>
                                        }
                                    </>
                                }
                            </div>
                            <div className="col">
                                Schwierigkeit: {getDifficultyLevelName(recipe.difficultyLevel)}
                            </div>
                        </div>
                    </div>
                </div>
                {pictures !== undefined && pictures !== null && imagesPerSlide !== undefined &&
                    <div className="row">
                        <div className="col-12">
                            <PictureSlider
                                pictures={pictures}
                                imagesPerSlide={imagesPerSlide}
                                getImageTag={getRecipeImage}
                            />
                        </div>
                    </div>
                }
                {components !== undefined &&
                    <>
                        {components.map((component: Component, index: number): React.JSX.Element => {
                            return (
                                <>
                                    <div className="row pt-3">
                                        <div className="col-12">
                                            {component.name}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {component.ingredients.length > 0 &&
                                            <div className="col-auto">
                                                <ul>
                                                    {component.ingredients.map((ingredient: Ingredient): React.JSX.Element => {
                                                       return (
                                                           <li>
                                                               {ingredient.quantity} {getUnitShortName(ingredient.unit)}. {ingredient.name}
                                                           </li>
                                                       );
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                        {component.workingSteps.length > 0 &&
                                            <div className="col-auto">
                                                <ul>
                                                    {component.workingSteps.map((workingStep: WorkingStep): React.JSX.Element => {
                                                        return (
                                                            <li>{workingStep.description}</li>
                                                        );
                                                    })}
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </>
                            );
                        })}
                    </>
                }
            </Board>
        </>
    );
}

export default RecipeView;