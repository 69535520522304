import React from "react";
import FontAwesomeIcon from "./FontAwesomeIcon";
import {Link} from "react-router-dom";
import BootstrapIcon from "./BootstrapIcon";
import MaterialIcon from "./MaterialIcon";

type IconType = 'Bootstrap' | 'FontAwsome' | 'Material';

interface IconTileProps {
    readonly icon: string;
    readonly iconType: IconType;
    readonly url: string;
    readonly label?: string;
}

const IconTile = (props: IconTileProps): React.JSX.Element => {
    return (
        <Link to={props.url} className="icon-tile">
            <div className="card card-shadow dashboard-navigation text-center highlight">
                {props.iconType === 'FontAwsome' &&
                    <FontAwesomeIcon iconClassName={props.icon} />
                }
                {props.iconType === 'Bootstrap' &&
                    <BootstrapIcon iconClassName={props.icon} />
                }
                {props.iconType === 'Material' &&
                    <MaterialIcon iconClassName={props.icon} />
                }
                {props.label !== undefined &&
                    <p className="d-none d-md-block d-xl-block h6 pt-2">{props.label}</p>
                }
            </div>
        </Link>
    );
}

export default IconTile;