import App from '../App';
import StartPage from '../Domain/Controller/StartPage';
import ProtectedRoute from './ProtectedRoute';
import Dashboard from '../Domain/Controller/Dashboard';
import AuthenticationService from '../Domain/Authentication/AuthenticationService';
import PublicBaseLayout from '../Domain/PublicBaseLayout';
import PublicRoute from "./PublicRoute";
import RecipeCategoriesOverview from '../Domain/Controller/Recipes/RecipeCategoriesOverview';
import {Router} from '@remix-run/router';
import {createBrowserRouter} from 'react-router-dom';
import RecipeCategoryOverview from "../Domain/Controller/Recipes/RecipeCategoryOverview";
import RecipeEditor from "../Domain/Controller/Recipes/RecipeEditor";
import AccountUsersOverview from "../Domain/Controller/AccountUser/AccountUsersOverview";
import AccountUserEditor from "../Domain/Controller/AccountUser/AccountUserEditor";
import DishOverview from "../Domain/Controller/Dish/DishOverview";
import DishEditor from "../Domain/Controller/Dish/DishEditor";
import RecipeView from "../Domain/Controller/Recipes/RecipeView";


const isAuthenticated = () : boolean => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    return authenticationService.fetchAuthentication() !== null;
}

const AppRouter:  Router = createBrowserRouter( [
    {
        element: <PublicRoute isAuthenticated={isAuthenticated}/>,
        children: [
            {
                element: <PublicBaseLayout/>,
                children: [
                    {
                        element: <StartPage/>,
                        path: '',
                    }
                ]
            }
        ]
    },
    {
        element: <ProtectedRoute isAuthenticated={isAuthenticated}/>,
        children: [
            {
                element: <App/>,
                children: [
                    {
                        path: '/dashboard',
                        element: <Dashboard/>,
                    },
                    {
                        path: '/rezepte',
                        element: <RecipeCategoriesOverview/>,
                    },
                    {
                        path: '/rezepte/:categoryId',
                        element: <RecipeCategoryOverview/>,
                    },
                    {
                        path: '/rezepte/editor',
                        element: <RecipeEditor/>,
                    },
                    {
                        path: '/rezepte/editor/:recipeId',
                        element: <RecipeEditor/>,
                    },
                    {
                        path: '/rezepte/:categoryId/:recipeId',
                        element: <RecipeView/>,
                    },
                    {
                        path: '/anwender',
                        element: <AccountUsersOverview/>,
                    },
                    {
                        path: '/anwender/editor',
                        element: <AccountUserEditor/>,
                    },
                    {
                        path: '/anwender/editor/:accountUserId',
                        element: <AccountUserEditor/>,
                    },
                    {
                        path: '/gerichte',
                        element: <DishOverview/>,
                    },
                    {
                        path: '/gerichte/editor',
                        element: <DishEditor/>,
                    },
                    {
                        path: '/gerichte/editor/:dishId',
                        element: <DishEditor/>,
                    }
                ]
            }],
    }]
);

export default AppRouter;
