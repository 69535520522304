import React, {MouseEvent, MouseEventHandler} from "react";

interface MaterialIconProps {
    readonly iconClassName: string;
    onClick?: MouseEventHandler;
}

const MaterialIcon = (props: MaterialIconProps): React.JSX.Element => {
    const onClick = (e: MouseEvent): void =>
    {
        if (props.onClick === undefined) {
            return;
        }

        props.onClick(e);
    }

    return (
        <>
            <span onClick={props.onClick} className="material-symbols-outlined">{props.iconClassName}</span>
        </>
    );
}

export default MaterialIcon;