import React, {ChangeEvent, ChangeEventHandler, MutableRefObject, useRef, useState} from "react";
import FieldProps from "./FieldProps";
import FieldLabel from "./FieldLabel";
import inputFieldStyle from "./InputField.module.scss";
import FieldStyle from "./Field.module.scss";
import FormError from "../../../Domain/Entity/Form/FormError";
import AutocompleteItem from './AutocompleteItem';
import IconButton from "../../IconButton";

interface AutocompleteProps extends FieldProps {
    readonly placeholder?: string;
    readonly value?: string | ReadonlyArray<string> | number;
    readonly type?: React.HTMLInputTypeAttribute;
    readonly max?: number | string;
    readonly maxLength?: number;
    readonly min?: number | string;
    readonly onChange?: ChangeEventHandler<HTMLInputElement>;
    readonly id?: string;
    readonly suggestions: AutocompleteItem[];
    readonly onSelect: Function;
    readonly onClickSuggestion?: Function;
    readonly showAddButton: boolean;
    readonly resetOnEnter: boolean;
}


const Autocomplete = (props: AutocompleteProps): React.JSX.Element => {
    const hasErrors: boolean = props.formErrors !== undefined && props.formErrors.length > 0;
    const [userInput, setUserInput] = useState(props.value);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeFilteredSuggestions, setActiveFilteredSuggestions] = useState<AutocompleteItem[]>([]);
    const [currentIndex, setCurrentIndex] = useState<number>(-1);
    const inputRef: MutableRefObject<HTMLInputElement|null> = useRef<HTMLInputElement>(null);

    function triggerOnChange(value: string): void
    {
        if (inputRef.current === null) {
            return;
        }

        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')?.set;
        if (nativeInputValueSetter) {
            nativeInputValueSetter.call(inputRef.current, value);
        }

        const event = new Event('input', { bubbles: true });
        inputRef.current.dispatchEvent(event);
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const userInput: string = e.target.value;

        if (props.onChange !== undefined) {
            props.onChange(e);
        }

        setUserInput(userInput);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const targetElement: HTMLInputElement = event.target as HTMLInputElement;
        const inputValue: string = targetElement.value;

        if (event.key === 'ArrowLeft'
            || event.key === 'ArrowRight'
            || event.key === 'ArrowUp'
            || event.key === 'ArrowDown'
            || event.key === 'Shift'
            || event.key === 'Enter'
        ) {
            return;
        }

        const filteredSuggestions: AutocompleteItem[] = props.suggestions.filter(
            (suggestion) =>
                suggestion.name.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
        );
        setCurrentIndex(-1);

        if (filteredSuggestions.length <= 0) {
            setShowSuggestions(false);
            setActiveFilteredSuggestions([]);
            return;
        }
        setActiveFilteredSuggestions(filteredSuggestions);
        setShowSuggestions(true);
    };

    const handleClick = (suggestion: string, index: number): void => {

        if (props.onClickSuggestion !== undefined) {
            props.onClickSuggestion(activeFilteredSuggestions[index]);
            setShowSuggestions(false);
            setActiveFilteredSuggestions([]);
            triggerOnChange(suggestion);
            return;
        }

        const filteredSuggestions: AutocompleteItem[] = [activeFilteredSuggestions[index]];
        setActiveFilteredSuggestions(filteredSuggestions);

        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
        triggerOnChange(suggestion);
    };

    const handleCombinedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (props.onChange !== undefined) {
            props.onChange(event);
        }
        handleChange(event);
    };

    function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        let activeFilteredSuggestionsIndex: number = currentIndex;

        if (event.key === 'Enter' && activeFilteredSuggestions.length > 0) {
            if (activeFilteredSuggestionsIndex === -1) {
                activeFilteredSuggestionsIndex = 0;
            }
            if (activeFilteredSuggestions[activeFilteredSuggestionsIndex].name === userInput) {
                props.onSelect(activeFilteredSuggestions[activeFilteredSuggestionsIndex].name, activeFilteredSuggestions[activeFilteredSuggestionsIndex].element);
                if (props.resetOnEnter === true) {
                    setUserInput('');
                }
                setShowSuggestions(false);
                return;
            }
            setShowSuggestions(false);
            triggerOnChange(activeFilteredSuggestions[activeFilteredSuggestionsIndex].name);
            //setUserInput(activeFilteredSuggestions[activeFilteredSuggestionsIndex].name);
        } else if (event.key === 'Enter') {
            props.onSelect(userInput, undefined);
            if (props.resetOnEnter === true) {
                setUserInput('');
            }
            return;
        }


        if (event.key === 'ArrowUp' && activeFilteredSuggestions.length > 0) {
            activeFilteredSuggestionsIndex--;

            if (activeFilteredSuggestionsIndex < 0) {
                activeFilteredSuggestionsIndex = activeFilteredSuggestions.length - 1;
            }

            setCurrentIndex(activeFilteredSuggestionsIndex);
            triggerOnChange(activeFilteredSuggestions[activeFilteredSuggestionsIndex].name);
            //setUserInput(activeFilteredSuggestions[activeFilteredSuggestionsIndex].name);

            return;

        } else if (event.key === 'ArrowDown' && activeFilteredSuggestions.length > 0) {
            activeFilteredSuggestionsIndex++;

            if (activeFilteredSuggestionsIndex >= activeFilteredSuggestions.length) {
                activeFilteredSuggestionsIndex = 0;
            }

            setCurrentIndex(activeFilteredSuggestionsIndex);
            triggerOnChange(activeFilteredSuggestions[activeFilteredSuggestionsIndex].name);
            setUserInput(activeFilteredSuggestions[activeFilteredSuggestionsIndex].name);

            return;
        }
    }

    const onClickAdd = (): void => {

        if (activeFilteredSuggestions.length > 0) {
            props.onSelect(activeFilteredSuggestions[0].name, activeFilteredSuggestions[0].element);
            setUserInput('');
            return;
        }

        props.onSelect(userInput, undefined);
        setUserInput('');
        return;
    }

    function handleOnBlur(): void
    {
    }

    return (
        <>

            <FieldLabel label={props.label} htmlFor={props.name} required={props.required} description={props.description} className="mb-2 form-field-label-text" />
            <div style={props.style} className={(props.suffix) && inputFieldStyle.inputGroup}>
                <div className="row">
                    <div className="col-10">
                        <input
                            ref={inputRef}
                            name={props.name}
                            required={props.required}
                            disabled={props.disabled}
                            className={[FieldStyle.container, hasErrors === true ? ' is-invalid form-control' : ''].join(' ')}
                            placeholder={props.placeholder}
                            value={userInput}
                            type={props.type}
                            max={props.max}
                            maxLength={props.maxLength}
                            min={props.min}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            onKeyDown={handleKeyDown}
                            onBlur={handleOnBlur}
                        />
                        {props.suffix !== undefined && <div className={inputFieldStyle.suffix}>{props.suffix}</div>}
                    </div>
                    {props.showAddButton === true &&
                        <>
                            <div className="col-2 d-flex justify-content-center align-items-end h4">
                                <div className="pointer-cursor">
                                    <IconButton icon="fa-square-plus" iconType="FontAwsome" onClick={onClickAdd} />
                                </div>
                            </div>
                        </>
                    }

                </div>
            </div>
            {showSuggestions && userInput && (
                <div className="suggestionContainer">
                    <ul className="suggestions">
                        {activeFilteredSuggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                onClick={(): void => handleClick(suggestion.name, index)}
                                className={'pointer-cursor' + (index === activeSuggestionIndex ? ' active' : '')}
                            >
                                {suggestion.name}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {hasErrors === true &&
                <>
                    {props.formErrors!.map((formError: FormError, index: number): React.JSX.Element => (
                        <div key={'input_error_' + props.name + '_' + index} className="invalid-feedback d-block">
                            {formError.message}
                        </div>
                    ))}
                </>
            }
        </>
    );
}

export default Autocomplete;