import React, {FormEvent, useEffect, useState} from "react";
import Authentication from "../../../Entity/Authentication/Authentication";
import AuthenticationService from "../../../Authentication/AuthenticationService";
import FormDataObject from "../../../Entity/Form/FormData";
import AccountUser from "../../../Entity/AccountUser/AccountUser";
import InputField from "../../../../Component/Form/Field/InputField";
import FormValidationHandler from "../../../FormValidationHandler/FormValidationHandler";
import DatePickerField from "../../../../Component/Form/Field/DatePickerField";
import SelectField from "../../../../Component/Form/Field/SelectField";
import SelectOption from "../../../Entity/Form/SelectOption";
import AccountUserRole from "../../../Entity/AccountUser/AccountUserRole";
import AccountUserService from "../../../AccountUser/AccountUserService";
import SpinnerOverlay from "../../../../Component/SpinnerOverlay";
import {SingleValue} from "react-select";
import AccountUserRequest from "../../../Entity/AccountUser/AccountUserRequest";
import {AxiosResponse} from "axios";
import {NavigateFunction, useNavigate} from "react-router-dom";

interface AccountUserFormProps {
    readonly formData: FormDataObject<AccountUser>;
    readonly formValidationHandler: FormValidationHandler<AccountUser>;
    readonly setFormData: Function;
}

const AccountUserForm = (props: AccountUserFormProps): React.JSX.Element => {
    const accountUser: AccountUser = props.formData.data;
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [accountUserRoles,setAccountUserRoles] = useState<AccountUserRole[]|undefined>(undefined);
    const [accountUserRoleOptions, setAccountUserRoleOptions] = useState<SelectOption<AccountUserRole>[]|undefined>(undefined);
    const [isLoading,setIsLoading] = useState<boolean>(false);
    const navigate: NavigateFunction = useNavigate();

    useEffect(():void => {
        if (accountUserRoles !== undefined) {
            return;
        }

        loadAccountUserRoles();

    }, [accountUserRoles]);

    useEffect(():void => {
        if (accountUserRoles === undefined || accountUserRoleOptions !== undefined) {
            return;
        }
        const roles: SelectOption<AccountUserRole>[] = [];

        accountUserRoles.map(
            (accountUserRole: AccountUserRole): void => {
                roles.push({label: accountUserRole.name, value: accountUserRole});
            }
        );

        setAccountUserRoleOptions(roles);

    }, [accountUserRoleOptions, accountUserRoles]);

    async function loadAccountUserRoles(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        setAccountUserRoles(await accountUserService.getAccountUserRoles());
    }

    const submitForm = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        if (authentication === null) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        const redirect: boolean = (accountUser.id === null)


        e.preventDefault();

        if (authentication === null) {
            return;
        }

        setIsLoading(true);

        const accountUserRequest: AccountUserRequest = {
            id: accountUser.id,
            email: accountUser.email,
            password: (accountUser.password !== null) ? accountUser.password : '',
            firstname: accountUser.firstname,
            name: accountUser.name,
            birthday: accountUser.birthday,
            roles: accountUser.roles,
        };

        if (accountUser.id === null) {
            const response: AxiosResponse = await accountUserService.post(accountUserRequest);
            accountUser.id = parseInt(response.headers['x-id']);
        } else {
            await accountUserService.put(accountUserRequest);
        }

        if (redirect) {
            navigate('/anwender/editor/' + accountUser.id);
        }

        setIsLoading(false);
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (accountUser as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: accountUser});
    };

    const handleAccountUserRoleChange = (selectedAccountUserRole: SingleValue<SelectOption<AccountUserRole>>): void => {
        (accountUser as any)['roles'] = [];

        if (selectedAccountUserRole === null) {
            return;
        }

        (accountUser as any)['roles'].push(selectedAccountUserRole['value'].role);
        props.setFormData({...props.formData, data: props.formData.data});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    function getAccountUserRoleSelectOptionFromValue(value: string): SelectOption<AccountUserRole>
    {
        if (accountUserRoles === undefined) {
            throw new Error();
        }
        let role: AccountUserRole|null = null;

        for(let i: number = 0; i < accountUserRoles.length; i++) {
            if (accountUserRoles[i].role !== value) {
                continue;
            }
            role = accountUserRoles[i];
            break;
        }

        if (role === null) {
            return {label: accountUserRoles[(accountUserRoles.length - 1)].name, value: accountUserRoles[(accountUserRoles.length - 1)] };
        }

        return {label: role.name, value: role};
    }

    if (accountUserRoleOptions === undefined) {
        return (
            <SpinnerOverlay />
        );
    }

    return (
        <>
            {isLoading &&
                <SpinnerOverlay />
            }
            <form onSubmit={submitForm} id="accountUserForm">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <InputField
                            name="name"
                            label="Name"
                            type="text"
                            value={(accountUser.name !== null) ? accountUser.name : ''}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'name')}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputField
                            name="firstname"
                            label="Vorname"
                            type="text"
                            value={(accountUser.firstname !== null) ? accountUser.firstname : ''}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'firstname')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <InputField
                            name="email"
                            label="Email"
                            type="email"
                            value={(accountUser.email !== null) ? accountUser.email : ''}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'email')}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <DatePickerField
                            name="birthday"
                            label="Geburstag"
                            value={(accountUser.birthday !== null) ? accountUser.birthday : ''}
                            onChange={handleChange}
                            required={false}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'birthday')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <SelectField
                            options={accountUserRoleOptions}
                            placeholder="Rolle"
                            value={getAccountUserRoleSelectOptionFromValue((accountUser.roles !== undefined) ? accountUser.roles[0] : '')}
                            onChange={handleAccountUserRoleChange}
                            label="Rolle"
                            name="roles"
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <InputField
                            name="password"
                            label="Kennwort"
                            type="password"
                            value={(accountUser.password !== null) ? accountUser.password : ''}
                            onChange={handleChange}
                            required={accountUser.id === null}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'password')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">

                    </div>
                    <div className="col-12 col-md-6">
                        <InputField
                            name="passwordRetype"
                            label="Kennwort wiederholen"
                            type="password"
                            value={(accountUser.passwordRetype !== null) ? accountUser.passwordRetype : ''}
                            onChange={handleChange}
                            required={accountUser.id === null}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'passwordRetype')}
                        />
                    </div>
                </div>
            </form>
        </>);
}

export default AccountUserForm;