import ApiV1Provider from "../../Api/ApiV1Provider";
import Authentication from '../Entity/Authentication/Authentication';
import {AxiosResponse} from "axios/index";
import {AxiosRequestConfig} from "axios";

class FileService {

    private readonly apiV1Provider: ApiV1Provider;

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
    }

    public async getFileData(id: number): Promise<string|undefined> {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/files/' + id.toString(), {
                headers: {
                    'Authorization': 'Bearer ' + this.apiV1Provider.token,
                }
            });

            if (!response.ok) {
                throw new Error('Fehler beim Laden des Bildes');
            }

            const bildBlob = await response.blob();
            return URL.createObjectURL(bildBlob);
        } catch (error) {
            console.error('Fehler:', error);
        }
    }

    public async getScaledImage(id: number, width: number, height: number): Promise<string|undefined> {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL + '/api/files/resized/' + width.toString() + 'X' + height.toString() +'/' + id.toString(), {
                headers: {
                    'Authorization': 'Bearer ' + this.apiV1Provider.token,
                }
            });

            if (!response.ok) {
                throw new Error('Fehler beim Laden des Bildes');
            }

            const bildBlob = await response.blob();
            return URL.createObjectURL(bildBlob);
        } catch (error) {
            console.error('Fehler:', error);
        }
    }

    public async post(formData: FormData): Promise<AxiosResponse>  {

        const config: AxiosRequestConfig<FormData> = {headers: {'Content-Type': 'multipart/form-data'}};

        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/files', formData, config);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default FileService;