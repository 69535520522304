import React, {useEffect, useState} from 'react';
import Board from './Component/Board';
import IconTile from "../../Component/IconTile";
import AccountUserService from "../AccountUser/AccountUserService";
import AuthenticationService from "../Authentication/AuthenticationService";
import Authentication from "../Entity/Authentication/Authentication";
import AccountUser from "../Entity/AccountUser/AccountUser";
import SpinnerOverlay from "../../Component/SpinnerOverlay";

interface DashboardProps {

}

const Dashboard = (props: DashboardProps): React.JSX.Element => {
    const authenticationService: AuthenticationService = new AuthenticationService();
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const [accountUser, setAccountUser] = useState<AccountUser|null|undefined>(undefined);


    async function loadAccountUser(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        const accountUserService: AccountUserService = new AccountUserService(authentication);
        setAccountUser(await accountUserService.profile());
    }

    useEffect((): void => {
        if (accountUser !== undefined || authentication === null) {
            return;
        }
        loadAccountUser();

    }, [accountUser, authentication]);


    if (accountUser === undefined) {
        return (
            <SpinnerOverlay />
        );
    } else if (authentication === null) {
        return (<></>);
    }

    const accountUserService: AccountUserService = new AccountUserService(authentication);

    return (
        <Board>
            <div className="d-flex flex-wrap gap-1">
                <div className="p-2">
                    <IconTile icon="bi-journal-bookmark" iconType="FontAwsome" url="/rezepte" label="Rezepte"/>
                </div>
                <div className="p-2">
                    <IconTile icon="restaurant_menu" iconType="Material" url="/gerichte" label="Gerichte"/>
                </div>
                {accountUser !== null && accountUserService.isGranted('ROLE_ADMINISTRATOR', accountUser.roles) === true &&
                    <div className="p-2">
                        <IconTile icon="fa-users" iconType="FontAwsome" url="/anwender" label="Anwender"/>
                    </div>
                }
            </div>
        </Board>
    );
}

export default Dashboard;
