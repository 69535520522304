import React, {MouseEventHandler} from "react";
import FontAwesomeIcon from "./FontAwesomeIcon";
import BootstrapIcon from "./BootstrapIcon";
import MaterialIcon from "./MaterialIcon";

type IconType = 'Bootstrap' | 'FontAwsome' | 'Material';

interface ButtonWithIconProps {
    readonly icon: string;
    readonly text: string;
    readonly onClick: MouseEventHandler;
    readonly iconType: IconType;
    readonly additionalClassName?: string;
}

const ButtonWithIcon = (props: ButtonWithIconProps): React.JSX.Element => {

    return (
        <div className={"primary-button" + ((props.additionalClassName !== undefined) ? ' ' + props.additionalClassName : '')} onClick={props.onClick}>
            {props.iconType === 'FontAwsome' &&
                <FontAwesomeIcon iconClassName={props.icon} />
            }
            {props.iconType === 'Bootstrap' &&
                <BootstrapIcon iconClassName={props.icon} />
            }
            {props.iconType === 'Material' &&
                <MaterialIcon iconClassName={props.icon} />
            }
            <div className="primary-button-text">
                {props.text}
            </div>
        </div>
    );
}

export default ButtonWithIcon;