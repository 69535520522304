import InputField from './InputField';
import FieldProps from './FieldProps';
import Pikaday from 'pikaday';
import 'pikaday/css/pikaday.css';
import React, {useRef, useEffect, useState} from 'react';

interface DatePickerFieldProps<T> extends FieldProps {
    readonly value?: string;
    readonly minDate?: Date;
    readonly maxDate?: Date;
    readonly onChange?: React.ChangeEventHandler<T>;
}

const DatePickerField = (props: DatePickerFieldProps<HTMLInputElement>): React.JSX.Element => {
    const inputRef: React.RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

    const [pikaday, setPikaday] = useState<Pikaday>();

    useEffect(() => {
        const pikaday: Pikaday = initializePikaday();

        setPikaday(pikaday);

        return (): void => {
            pikaday.destroy();
        };
    }, []);

    const initializePikaday = (): Pikaday => {
        return new Pikaday({
            field: inputRef.current,
            format: 'DD.MM.YYYY',
            defaultDate: props.value !== undefined ? new Date(props.value) : new Date(),
            i18n: {
                previousMonth: 'Vorheriger Monat',
                nextMonth: 'Nächster Monat',
                months:  ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
                weekdays: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
                weekdaysShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa']
            },
            minDate: props.minDate ?? undefined,
            maxDate: props.maxDate ?? undefined,
            onSelect: handlePikadayOnSelect
        });
    };

    const handlePikadayOnSelect = (date: Date): void => {
        if (props.onChange !== undefined) {
            props.onChange({
                target: {
                    name: props.name,
                    value: formatDateToString(updateTimeZoneOnDate(date)),
                }
            } as React.ChangeEvent<HTMLInputElement>); //TODO CustomChangeEvent type instead of HTMLInputElement?
        }
    };

    const updateTimeZoneOnDate = (date: Date): Date => {
        const timeZoneOffset: number = date.getTimezoneOffset() * 60000;
        const adjustedTime: number = date.getTime() - timeZoneOffset;

        date.setTime(adjustedTime);

        return date;
    };

    const formatDateToString = (date: Date): string => {
        return date.toISOString().split('T')[0];
    };

    const formatDateStringFromDbFormatToDisplayFormat = (dateString: string): string => {
        return dateString.split('-').reverse().join('.');
    };

    return (
        <InputField
            ref={inputRef}
            label={props.label}
            name={props.name}
            value={props.value !== undefined ? formatDateStringFromDbFormatToDisplayFormat(props.value) : undefined}
        />
    );
};

export default DatePickerField;
