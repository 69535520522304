import ApiV1Provider from '../../Api/ApiV1Provider';
import Authentication from '../Entity/Authentication/Authentication';
import {AxiosResponse} from 'axios';
import LocalStorageService from "../LocalStorage/LocalStorageService";
import Dish from "../Entity/Dish/Dish";
import DishResponse from "../Entity/Dish/DishResponse";
import DishRequest from "../Entity/Dish/DishRequest";
import Recipe from "../Entity/Recipe/Recipe";
import RecipeRequest from "../Entity/Recipe/RecipeRequest";
import RecipeCategory from "../Entity/RecipeCategory/RecipeCategory";

class DishService {
    private readonly apiV1Provider: ApiV1Provider
    private readonly localStorageService: LocalStorageService;

    constructor(authentication: Authentication) {
        this.apiV1Provider = new ApiV1Provider(process.env.REACT_APP_API_URL + '/api', authentication.token);
        this.localStorageService = new LocalStorageService();
    }

    public async get(): Promise<Dish[]> {
        const dishes: Dish[] = [];
        try {
            const response: AxiosResponse<DishResponse[]> = await this.apiV1Provider.get('/dishes');
            const dishResponses: DishResponse[] = response.data;

            dishResponses.map(
                (dishResponse: DishResponse): void => {
                    dishes.push(Dish.createFromDishResponse(dishResponse));
                }
            );

            return dishes;
        } catch (error) {
            throw error;
        }
    }

    public async getById(id: number): Promise<Dish> {

        try {
            const response: AxiosResponse<DishResponse> = await this.apiV1Provider.get('/dishes/' + id);
            const dishResponse: DishResponse = response.data;

            return Dish.createFromDishResponse(dishResponse);
        } catch (error) {
            throw error;
        }
    }

    public async post(dishRequest: DishRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.post('/dishes', dishRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async put(dishRequest: DishRequest): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.put('/dishes/' + dishRequest.id, dishRequest);
            return response;
        } catch (error) {
            throw error;
        }
    }

    public async delete(dishId: number): Promise<AxiosResponse>  {
        try {
            const response: AxiosResponse = await this.apiV1Provider.delete('/dishes/' + dishId);
            return response;
        } catch (error) {
            throw error;
        }
    }
}

export default DishService;
