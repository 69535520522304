import Recipe from '../../../Entity/Recipe/Recipe';
import FormDataObject from '../../../Entity/Form/FormData';
import React, {FormEvent, useEffect, useState} from 'react';
import FormValidationHandler from '../../../FormValidationHandler/FormValidationHandler';
import InputField from '../../../../Component/Form/Field/InputField';
import MultiSelectField from '../../../../Component/Form/Field/MultiSelectField';
import AuthenticationService from '../../../Authentication/AuthenticationService';
import Authentication from '../../../Entity/Authentication/Authentication';
import RecipeCategory from '../../../Entity/RecipeCategory/RecipeCategory';
import RecipeCategoryService from '../../../RecipeCategory/RecipeCategoryService';
import SelectOption from '../../../Entity/Form/SelectOption';
import SpinnerOverlay from '../../../../Component/SpinnerOverlay';
import DropZone from '../../../../Component/DropZone';
import Ingredient from "../../../Entity/Recipe/Ingredient";
import AutocompleteItem from "../../../../Component/Form/Field/AutocompleteItem";
import IngredientService from "../../../Ingredient/IngredientService";
import RecipeService from "../../../Recipe/RecipeService";
import RecipeRequest from "../../../Entity/Recipe/RecipeRequest";
import {AxiosResponse} from "axios";
import {NavigateFunction, useNavigate} from "react-router-dom";
import CardSlider from "../../../../Component/CardSlider";
import Component from "../../../Entity/Recipe/Component";
import ComponentService from "../../../Recipe/ComponentService";
import RecipeComponent from "./RecipeComponent";
import {Carousel} from "react-bootstrap";
import ComponentRequest from "../../../Entity/Recipe/ComponentRequest";
import IngredientRequest from "../../../Entity/Recipe/IngredientRequest";
import PictureService from "../../../Image/PictureService";
import Picture from "../../../Entity/Picture/Picture";
import RecipeFormImageCard from "./RecipeFormImageCard";
import WorkingStepRequest from "../../../Entity/Recipe/WorkingStepRequest";
import WorkingStepService from "../../../WorkingStep/WorkingStepService";
import SelectField from "../../../../Component/Form/Field/SelectField";
import DifficultyLevel from "../../../Entity/Enums/DifficultyLevel";
import {SingleValue} from "react-select";
import TimePicker from "../../../../Component/TimePicker";
import PictureSlider from "../../Component/PictureSlider";

interface RecipeFormProps {
    readonly formData: FormDataObject<Recipe>;
    readonly formValidationHandler: FormValidationHandler<Recipe>;
    readonly setFormData: Function;
    readonly className?: string;
}

const RecipeForm = (props: RecipeFormProps): React.JSX.Element => {
    const recipe: Recipe = props.formData.data;
    const authenticationService: AuthenticationService = new AuthenticationService();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [categoryOptions, setCategoryOptions] = useState<SelectOption<RecipeCategory>[]|undefined>(undefined);
    const [components, setComponents] = useState<Component[]|undefined>(undefined);
    const [files, setFiles] = useState<File[]|undefined>(undefined)
    const [triggerClearFilesEvent,setTriggerClearFilesEvent] = useState<boolean>(false)
    const [autocompleteItems, setAutocompleteItems] = useState<AutocompleteItem[]|undefined>(undefined);
    const [pictures, setPictures] = useState<Picture[]|null|undefined>(undefined);
    const authentication: Authentication|null = authenticationService.fetchAuthentication();
    const navigate: NavigateFunction = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [imagesPerSlide, setImagesPerSlide] = useState<number|undefined>(undefined);
    const windowWidth: number = window.innerWidth;
    const difficultyLevels: SelectOption<DifficultyLevel>[] =
        [
            {label: 'Einfach', value: DifficultyLevel.LOW},
            {label: 'Mittel', value: DifficultyLevel.MEDIUM},
            {label: 'Schwer', value: DifficultyLevel.HIGH},
        ];

    function buildClassName(): string
    {
        return 'recipe-form' + ((props.className !== undefined) ? ' ' + props.className : '');
    }

    function onDeletePicture(picture: Picture): void
    {
        setPictures(undefined);
    }

    function getRecipeImageFormCard(picture: Picture): React.JSX.Element
    {
        return(
            <RecipeFormImageCard picture={picture} onDelete={onDeletePicture} />
        );
    }

    async function fetchAllRecipeCategories(): Promise<void>
    {
        if (authentication === null) {
            return;
        }
        setIsLoading(true);

        const recipeCategoryService: RecipeCategoryService = new RecipeCategoryService(authentication);
        const recipeCategories: RecipeCategory[] =  await recipeCategoryService.fetchAll();
        const categoryOptions: SelectOption<RecipeCategory>[] = [];

        recipeCategories.map((recipeCategory: RecipeCategory):void => {
            categoryOptions.push({label: recipeCategory.name, value: recipeCategory})
        })

        setCategoryOptions(categoryOptions);

        setIsLoading(false);
    }

    async function loadPictures(): Promise<void>
    {
        if (authentication === null || recipe.id === undefined) {
            return;
        }
        const pictureService: PictureService = new PictureService(authentication);
        const picturesByRecipe: Picture[] =  await pictureService.getByRecipeId(recipe.id);

        if (picturesByRecipe.length <= 0) {
            console.log("no pictures");
            setPictures(null);
            return;
        }

        setPictures(picturesByRecipe);
    }

    useEffect((): void => {
        if (imagesPerSlide !== undefined) {
            return;
        }
        if (windowWidth <= 387) {
            setImagesPerSlide(1);
        } else if (windowWidth <= 1205) {
            setImagesPerSlide(3);
        } else {
            setImagesPerSlide(5);
        }

    }, [imagesPerSlide]);


    useEffect((): void => {
            if (categoryOptions !== undefined) {
                return;
            }
            fetchAllRecipeCategories();
        },
        [categoryOptions]
    );

    useEffect((): void => {
        if (components !== undefined) {
            return;
        }

        loadComponents();

    }, [recipe]);

    useEffect((): void => {
        if (pictures !== undefined) {
            return;
        }

        loadPictures();

    }, [recipe, pictures]);

    useEffect((): void => {
        if (components === undefined) {
            return;
        }

        setActiveIndex((components.length - 1))
    }, [components]);

    useEffect((): void => {
        if (files !== undefined) {
            return;
        }
        setTriggerClearFilesEvent(true);
    }, [files]);

    async function loadComponents(): Promise<void>
    {
        if (authentication === null || recipe.id === null || recipe.id === undefined) {
            const component: Component = new Component();
            component.name = 'Zubereitung';
            setComponents([component]);
            return;
        }
        const componentService: ComponentService = new ComponentService(authentication);
        const components: Component[] = await componentService.fetchByRecipeId(recipe.id);

        if (components.length <= 0) {
            const component: Component = new Component();
            component.name = 'Zubereitung';
            setComponents([component]);
            return;
        }

        setComponents(components);
    }

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>): void => {
        (recipe as any)[event.target.name] = event.target.value;

        updateFormData();
        validateField(event.target.name);
    };

    const updateFormData = (): void => {
        props.setFormData({...props.formData, data: recipe});
    };

    const validateField = (fieldName: string): void => {
        if (props.formValidationHandler === undefined) {
            return;
        }

        props.formValidationHandler.validateField(fieldName, props.formData);

        props.setFormData({...props.formData, errors: props.formData.errors});
    };

    const handleRecipeCategoryChange = (selectedCategories: readonly SelectOption<RecipeCategory>[]): void => {
        props.formData.data.recipeCategories = selectedCategories.map((selectedCategoryOption: SelectOption<RecipeCategory>): RecipeCategory => {
            return selectedCategoryOption.value;
        });

        props.setFormData({...props.formData, data: props.formData.data});
    };

    const handleChangePreparationTime = (seconds: number): void => {
        (recipe as any)['preparationTime'] = seconds;
        updateFormData();
        validateField('preparationTime');
    }

    const handleChangeCookingTime = (seconds: number): void => {
        (recipe as any)['cookingTime'] = seconds;
        updateFormData();
        validateField('cookingTime');
    }

    function getRecipeCategoryOptions(recipeCategories: RecipeCategory[]): SelectOption<RecipeCategory>[]
    {
        if (recipeCategories === undefined || recipeCategories.length <= 0) {
            return [];
        }
        return recipeCategories.map((recipeCategory: RecipeCategory): SelectOption<RecipeCategory> => {
            return {label: recipeCategory.name, value: recipeCategory};
        });
    }

    async function dropZoneCallback(files: File[]): Promise<void> {
        setFiles(files);
        setTriggerClearFilesEvent(false);
    }

    const submitForm = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
        let redirect: boolean = false;

        e.preventDefault();

        if (authentication === null) {
            return;
        }

        const recipeService: RecipeService = new RecipeService(authentication);
        const componentService: ComponentService = new ComponentService(authentication);
        const ingredientService: IngredientService = new IngredientService(authentication);
        const workingStepService: WorkingStepService = new WorkingStepService(authentication);
        const pictureService: PictureService = new PictureService(authentication);

        setIsLoading(true);

        const recipeRequest: RecipeRequest = recipeService.createRecipeRequestFromRecipe(recipe);

        if(recipe.id === undefined) {
            const response: AxiosResponse = await recipeService.post(recipeRequest);
            recipe.id = parseInt(response.headers['x-id']);
            redirect = true;
        } else {
            await recipeService.put(recipeRequest);
        }

        if (files !== undefined) {

            let countFiles = files.length;

            for (let i = 0; i < countFiles; i++) {

                let formData = new FormData();
                formData.append('id', recipe.id.toString());
                formData.append('file', files[i]);
                await pictureService.postToRecipe(formData);
            }

            setFiles(undefined);
            setPictures(undefined);
        }

        if (components === undefined) {
            if (redirect) {
                navigate('/rezepte/editor/' + recipe.id);
            }
            return;
        }

        for(let i: number = 0; i < components.length; i++) {

            const componentRequest: ComponentRequest = {
                id: (components[i].id === undefined) ? null : components[i].id,
                name: components[i].name
            }

            if (componentRequest.id === null) {
                const response: AxiosResponse = await componentService.post(recipe.id, componentRequest);
                components[i].id = parseInt(response.headers['x-id']);
            } else {
                await componentService.put(recipe.id, componentRequest);
            }

            if (components[i].ingredients !== undefined) {
                for(let x: number = 0; x < components[i].ingredients.length; x++) {
                    const ingredientRequest: IngredientRequest = {
                        id: (components[i].ingredients[x].id === undefined) ? null : components[i].ingredients[x].id,
                        name: components[i].ingredients[x].name,
                        description: components[i].ingredients[x].description,
                        unit: components[i].ingredients[x].unit,
                        quantity: components[i].ingredients[x].quantity
                    }

                    if (ingredientRequest.id === null) {
                        const response: AxiosResponse = await ingredientService.post(recipe.id, components[i].id, ingredientRequest);
                        components[i].ingredients[x].id = parseInt(response.headers['x-id']);
                    } else {
                        await ingredientService.put(recipe.id,components[i].id,ingredientRequest);
                    }
                }
            }

            if (components[i].workingSteps !== undefined) {
                for(let x: number = 0; x < components[i].workingSteps.length; x++) {
                    const workingStepRequest: WorkingStepRequest = {
                        id: (components[i].workingSteps[x].id === undefined) ? null : components[i].workingSteps[x].id,
                        description: components[i].workingSteps[x].description
                    }

                    if (workingStepRequest.id === null) {
                        const response: AxiosResponse = await workingStepService.post(recipe.id, components[i].id, workingStepRequest);
                        components[i].workingSteps[x].id = parseInt(response.headers['x-id']);
                    } else {
                        await workingStepService.put(recipe.id,components[i].id, workingStepRequest);
                    }
                }
            }
        }

        if (redirect) {
            navigate('/rezepte/editor/' + recipe.id);
        }

        setIsLoading(false);
    }

    async function fetchAllIngredients(): Promise<void>
    {
        if (authentication === null) {
            return;
        }

        const ingredientService: IngredientService = new IngredientService(authentication);
        const ingredients: Ingredient[] =  await ingredientService.fetchAll();
        const autocompleteItems: AutocompleteItem[] = [];

        ingredients.map((ingredient: Ingredient) => {
            autocompleteItems.push({name: ingredient.name, element: ingredient});
        });

        setAutocompleteItems(autocompleteItems);
    }

    useEffect((): void => {
        if (autocompleteItems !== undefined) {
            return;
        }

        fetchAllIngredients();

    }, [autocompleteItems]);


    if (categoryOptions === undefined || autocompleteItems === undefined) {
        return (
            <SpinnerOverlay />
        );
    }

    const addRecipeComponent = (): void => {
        const component: Component = new Component();
        const currentComponents: Component[] = [];

        if (components !== undefined) {
            components.map((e: Component): void => {
                currentComponents.push(e);
            });
        }

        component.name = 'Abschnitt ' + currentComponents.length;
        currentComponents.push(component);

        setComponents(currentComponents);
    }

    const removeRecipeComponent = (index: number): void => {
        if (authentication === null) {
            return;
        }

        const currentComponents: Component[] = [];
        const componentService: ComponentService = new ComponentService(authentication);

        if (components !== undefined) {
            components.map((e: Component, i: number): void => {
                if (index === i) {
                    if (e.id !== undefined && recipe.id) {
                        componentService.delete(recipe.id, e.id);
                    }
                    return;
                }
                currentComponents.push(e);
            });
        }

        setComponents(currentComponents);
    }

    const handleSelect = (selectedIndex: number): void => {
        setActiveIndex(selectedIndex);
    };

    const handleDifficultyLevelChange = (difficultyLevel: SingleValue<SelectOption<DifficultyLevel>>): void => {
        (recipe as any)['difficultyLevel'] = DifficultyLevel.LOW;

        if (difficultyLevel === null) {
            return;
        }

        (recipe as any)['difficultyLevel'] = difficultyLevel['value'];
        props.setFormData({...props.formData, data: props.formData.data});
    };

    function getDifficultyLevelSelectOptionFromValue(difficultyLevel: DifficultyLevel): SelectOption<DifficultyLevel>
    {
        if (difficultyLevel === DifficultyLevel.LOW) {
            return difficultyLevels[0];
        } else if (difficultyLevel === DifficultyLevel.MEDIUM) {
            return difficultyLevels[1];
        } else {
            return difficultyLevels[2];
        }
    }

    return (
        <div className={buildClassName()}>
            {isLoading === true &&

                <SpinnerOverlay />
            }
            <form onSubmit={submitForm} id="recipeForm">
                <div className="row">
                    <div className="col-12">
                        <InputField
                            name="name"
                            label="Name"
                            type="text"
                            value={recipe.name}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'name')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <InputField
                            name="persons"
                            label="Anzahl der Personen"
                            type="text"
                            value={recipe.persons}
                            onChange={handleChange}
                            required={true}
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'persons')}
                        />
                    </div>
                    <div className="col-6">
                        <TimePicker
                            name="preparationTime"
                            label="Vorbereitungszeit"
                            value={((recipe.preparationTime !== null) ? recipe.preparationTime : 0)}
                            onChange={handleChangePreparationTime}
                            required={true}
                        />
                    </div>
                    <div className="col-6">
                        <SelectField
                            options={difficultyLevels}
                            placeholder="Schwierigkeit"
                            value={getDifficultyLevelSelectOptionFromValue((recipe.difficultyLevel !== undefined) ? recipe.difficultyLevel : DifficultyLevel.LOW)}
                            onChange={handleDifficultyLevelChange}
                            label="Schwierigkeit"
                            name="difficultyLevel"
                        />
                    </div>
                    <div className="col-6">
                        <TimePicker
                            name="cookingTime"
                            label="Kochzeit"
                            value={((recipe.cookingTime !== null) ? recipe.cookingTime : 0)}
                            onChange={handleChangeCookingTime}
                            required={true}
                        />
                    </div>
                    <div className="col-12">
                        <MultiSelectField
                            label="Kategorien"
                            name="categories"
                            options={categoryOptions}
                            required={true}
                            closeMenuOnSelect={true}
                            value={getRecipeCategoryOptions(props.formData.data.recipeCategories)}
                            placeholder="Bitte wählen"
                            onChange={handleRecipeCategoryChange}
                            description="Rezept Kategorien, mehrfachauswahl möglich."
                            formErrors={FormValidationHandler.getFieldErrors(props.formData, 'categories')}
                        />
                    </div>
                </div>
                <div className="row">
                </div>
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <DropZone callback={dropZoneCallback} disabled={false} title="Rezept Bild" multiple={true} triggerClearEvent={triggerClearFilesEvent}>
                            {pictures !== undefined && pictures !== null && imagesPerSlide !== undefined &&
                                <PictureSlider
                                    pictures={pictures}
                                    imagesPerSlide={imagesPerSlide}
                                    getImageTag={getRecipeImageFormCard}
                                />
                            }
                        </DropZone>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>
            </form>
            {components !== undefined &&
                <div className="col-12">
                    <CardSlider activeIndex={activeIndex} onSelect={handleSelect}>
                        {components.map((component: Component, index: number): React.JSX.Element => {
                            return (
                                <Carousel.Item>
                                    <RecipeComponent
                                        addComponentCallback={addRecipeComponent}
                                        removeComponentCallback={(index > 0) ? removeRecipeComponent : undefined}
                                        recipe={recipe}
                                        component={component}
                                        autocompleteItems={autocompleteItems}
                                        index={index}
                                    />
                                </Carousel.Item>
                            );
                        })}
                    </CardSlider>
                </div>
            }
        </div>
    );
};

export default RecipeForm;